.ubf-form {
	position: relative;

	&__button {
		margin-left: 0;
		margin-bottom: $base2;
		margin-top: $base2;

		// .ubf-form--login &:last-child {
		// 	margin-top: $base2;
		// }

		.ubf-form > &:first-child {
			margin-top: 0;
		}

		.ubf-form__buttons > & {
			margin-left: $base2;
			margin-top: 0;
		}
	}

	&__button--transparent {
		border-color: transparent!important;
		background-color: transparent!important;
	}


	&__button--transparent {
		border-color: transparent!important;
		background-color: transparent!important;
	}


	&__buttons {
		// this removes the space between inline-blocks / inline-flex elements.
		// the proper font size is set in the children
		font-size: 0;
		margin-left: -$base2;
		margin-bottom: -$base2;

		* + & {
			margin-top: $base3;

			.ubf-form--login & {
				margin-top: $base2;
			}
		}

		.ubf-form__separator + & {
			margin-top: $base4;
		}
	}


	&__field {
		width: dynamicColumnSize(1, 1, $gridGutterLarge);

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(4, 9, $gridGutterLarge);
		}

		.ubf-contentBlock--formSmall & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(4, 6, $gridGutterLarge);
			}
		}

		&--half {
			.ubf-contentBlock--formSmall & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(3, 6, $gridGutterLarge);
				}
			}
		}

		&--full {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(8, 9, $gridGutterLarge);
			}

			.ubf-contentBlock--formSmall & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(6, 6, $gridGutterLarge);
				}
			}
		}


		.ubf-form--login & {
			width: dynamicColumnSize(1, 1, $gridGutterLarge);

			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 1, $gridGutterLarge);
			}
		}

		.ubf-form &--alone {
			@include media($largeLayoutBreakpoint) {
				margin-right: dynamicColumnPush(5, 9, $gridGutterLarge, true);
			}

			.ubf-contentBlock--formSmall & {
				@include media($largeLayoutBreakpoint) {
					margin-right: dynamicColumnPush(2, 6, $gridGutterLarge, true);
				}
			}
		}

		.ubf-form__separator + & {
			margin-top: $base4;
		}

		.ubf-form__separator + &:not(&--full):not(&--alone) + &:not(&--full):not(&--alone) {
			@include media($largeLayoutBreakpoint) {
				margin-top: $base4;
			}
		}
	}


	&__fields {
		@include dynamicColumns($hSpace: $gridGutterLarge, $vSpace: $base3, $hSpacePos: left, $vSpacePos: top);
		position: relative;

		.ubf-form--login & {
			@include dynamicColumns($hSpace: $gridGutterLarge, $vSpace: $base2, $hSpacePos: left, $vSpacePos: top);
		}

		.ubf-form--tableSearch & {
			display: none;
		}


		.ubf-form--tableSearch.ubf-js-searchable & {
			display: block;
		}
	}


	&__separator {
		@include ubf-highlightColor(background-color);
		border: 0;
		height: $boxBorderSize;
		margin-bottom: 0;
		display: block;
		width: 100%;

		.ubf-form__fields & {
			width: dynamicColumnSize(9, 9, $gridGutterLarge);

			.ubf-body--ekvv & {
				background-color: $colorEkvvBgAlternative;

				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(8, 9, $gridGutterLarge);
				}
			}
		}

		* + & {
			margin-top: $base4;
		}
	}


	&__text {
		.ubf-form__fields & {
			width: dynamicColumnSize(1, 1, $gridGutterLarge);
		}

		.ubf-form__buttons & {
			@include ubf-font(base);
			margin: $base3 0 $base3 $base2;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.ubf-form > & {
			margin-bottom: $base3;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.ubf-form > * + & {
			margin-top: $base3;
		}

		.ubf-form__separator + & {
			margin-top: $base4;
		}
	}
}
