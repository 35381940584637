
@mixin ubf-toggleContainer($apply: true, $onlyLarge: false) {
	padding-left: if($apply, $containerPaddingSmall, 0);
	padding-right: if($apply, $containerPaddingSmall, 0);
	margin-left: auto;
	margin-right: auto;
	max-width: none;
	width: 100%;

	@if ($onlyLarge == false) {
		@include media($ibridLayoutBreakpoint) {
			max-width: if($apply, $containerMaxWidthIbrid, none);
			padding-left: if($apply, $containerPaddingIbrid, 0);
			padding-right: if($apply, $containerPaddingIbrid, 0);
		}
	}

	@include media($largeLayoutBreakpoint) {
		max-width: if($apply, $containerMaxWidthLarge, none);
		padding-left: if($apply, $containerPaddingLarge, 0);
		padding-right: if($apply, $containerPaddingLarge, 0);
	}

	.ubf-body--ekvv & {
		@if ($onlyLarge == false) {
			@include media($ibridLayoutBreakpoint) {
				max-width: none;
				padding-left: if($apply, $containerPaddingSmall, 0);
				padding-right: if($apply, $containerPaddingSmall, 0);
			}
		}

		@include media($largeLayoutBreakpoint) {
			max-width: if($apply, $ekvvContainerMaxWidth, none);
			padding-left: if($apply, $logoMarginLarge, 0);
			padding-right: if($apply, $logoMarginLarge, 0);
		}
	}
}
