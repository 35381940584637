.ubf-disturber {
	&__icon {
		color: $colorHighlightBase;
		margin-left:-6px;
	}
	&__text {
		font-size: 18px;
		font-weight: 700;

	}
	&__link {
		color: $colorBase;
		text-decoration: underline;
	}
}
