.ubf-tabs {
	@include storeValuesForJs((tabsEnabled: false));
	position: relative;
	@include media($largeLayoutBreakpoint) {
		@include ubf-highlightColor(border-color);
		border-style: solid;
		border-width: 0 0 $boxBorderSize 0;
	}

	html.js & {
		@include media($largeLayoutBreakpoint) {

			@include ease(height, $duration4);
			@include storeValuesForJs((tabsEnabled: true));
			align-items: stretch;
			align-content: flex-start;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			overflow: hidden;
			padding-bottom: $base2;

		}
	}

	ol {
		list-style-position: inside;
		margin-left: 0!important;
	}



	&__content {
		@include dynamicColumns($hSpace: $gridGutterSmall, $vSpace: $gridGutterLarge, $hSpacePos: left, $vSpacePos: bottom);
		@include ease(opacity visibility, $duration4);
		left: 0;
		padding: $base 0 0 $base;
		position: relative;
		right: 0;
		top: 0;

		&:focus {
			outline: 0;
		}


		@include media($largeLayoutBreakpoint) {
			@include floatColumns($hSpace: $gridGutterLarge, $vSpace: $gridGutterLarge, $hSpacePos: left, $vSpacePos: bottom);
		}


		html.js & {
			@include media($largeLayoutBreakpoint) {
				@include ease(opacity visibility, $duration4);
				padding: 0;
			}
		}

		&.ubf-js-collapsed {
			opacity: 0;
			visibility: hidden;
			position: absolute;

			html.no-js &:focus-within {
				opacity: 1;
				position: relative;
				visibility: inherit;
			}

			html.js & {
				@include media($largeLayoutBreakpoint) {
					opacity: 1;
					position: relative;
					visibility: inherit;
				}
			}
		}

		html.js .ubf-tabs__contentWrapper:not(.ubf-js-selected) & {
			@include media($largeLayoutBreakpoint) {
				opacity: 0;
				position: absolute;
			}
		}
	}

	&__contentWrapper {
		@include ubf-highlightColor(border-color);
		@include ease(height, $duration4);
		border-style: solid;
		border-width: 0 0 0 $boxBorderSize;
		margin: 0 0 $base 0;
		overflow: hidden;
		position: relative;
		width: 100%;

		html.js & {
			@include media($largeLayoutBreakpoint) {
				border: 0;
				margin: 0;
				order: 10;
				overflow: visible;
				transition: none;
				z-index: 1;
				visibility: hidden;
			}
		}

		html.js &.ubf-js-selected {
			@include media($largeLayoutBreakpoint) {
				order: 20;
				visibility: inherit;
				z-index: 20;
			}
		}
	}


	&__tab {
		width: 100%;

		html.js & {
			@include media($largeLayoutBreakpoint) {
				margin-bottom: $base2;
				margin-right: $gridGutterLarge;
				order: 1;
				width: staticColumnSize(2, 9, $gridGutterLarge);
			}
		}
	}


	&__tabIcon {
		@include ease(transform, $duration4);
		display: inline-block;
		height: $base;
		line-height: 0;
		position: relative;
		transform: rotate(90deg);
		width: auto;

		.ubf-tabs__tabLink.ubf-js-toggled & {
			transform: rotate(270deg);
		}

		html.js & {
			@include media($largeLayoutBreakpoint) {
				display: none;
			}
		}


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}


	&__tabLabel {
		display: inline-block;
	}


	&__tabLink {
		@include ubf-outlineOnFocus();
		@include storeValuesForJs((togglerEnabled: true));
		@include ease(background-color border-color, $duration4);
		border-color: transparent;
		border-style: solid;
		border-width: 0 0 0 $boxBorderSize;

		align-items: center;
		background-color: $colorBgTabs;
		color: $colorTabs;
		display: flex;
		flex-direction: row;
		height: 100%;
		justify-content: space-between;
		padding: $baseHalf $base;
		position: relative;
		text-decoration: none;

		html.js & {
			@include media($largeLayoutBreakpoint) {
				@include storeValuesForJs((togglerEnabled: false));
				border-style: solid;
				border-width: $boxBorderSize 0;
			}
		}

		&.ubf-js-toggled {
			@include ubf-highlightColor(border-color);
			background-color: transparent;
		}

		&.ubf-js-selected {
			@include media($largeLayoutBreakpoint) {
				@include ubf-highlightColor(border-top-color);
				border-bottom-color: transparent;
				background-color: transparent;
			}
		}
	}
}
