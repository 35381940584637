.ubf-previewsSliderPaging {

	&__icon {
		@include ease(background-color, $duration2);
		@include ubf-highlightColor(color);
		border: $boxBorderSize solid currentColor;
		display: inline-block;
		height: $galleryNavigationIconSize;
		position: relative;
		width: $galleryNavigationIconSize;
		pointer-events: none;

		.ubf-body:not(.ubf-body--faculty) & {
			color: $colorHighlightBase;
		}

		[data-type="svg"] {
			height: 100%;
			width: auto;
			display: none;
		}
		.ubf-js-current & {
			background-color: currentColor;
		}

	}



	&__item {
		line-height: 0;
		margin-right: 2px;
		pointer-events: none;

		&:last-child {
			margin-right: 0;
		}

	}


	&__items {
		display: flex;
		flex-direction: row;
		justify-content: center;
		pointer-events: none;
	}


	&__link {
		align-items: center;
		display: flex;
		justify-content: center;
		min-height: $base3;
		pointer-events: all;
		width: 100%;

		&:not(:hover):focus {
			outline: $focusOutlineStyle $colorInverted 1px;
			outline-offset: $baseHalf;
		}

	}
}
