.ubf-detailedList {

	&__text {
		margin-bottom: $base2;
	}


	&__listItem > * {
		margin-top: $base1;
	}


	&__listItem + &__listItem {
		margin-top: $base2;
		padding-top: $base2;
		border-top: $colorTableCellBorder solid $boxBorderSize;
	}
}
