// Grid system ideal when you know in advance the exact layout of the full grid
//
// Features:
// 	- can be directly nested (a cell can be a container of an inner grid)
// 	- does not have any strong limitation on the container, so it can also have padding, max-width, and so on
// Limitations:
// 	- it is automatically distributing the space between the cells, so it needs a push on the right
//		to close the last row if the cells do not fill the total number of columns
// 		(every row needs to have exactly the total number of columns, summing up real cells and empty gaps created with the push)
//
// Alternative: _dynamic-columns.scss

// grid container
@mixin staticColumns($vSpace: false, $vSpacePos: top, $setChildrenStyle: true) {
	display: flex;
	flex-direction: row;
	flex-wrap: if($vSpace != false, wrap, nowrap);
	justify-content: space-between;
	@if ($vSpace != false) {
		margin-#{$vSpacePos}: -$vSpace;
	}

	@if ($setChildrenStyle) {
		& > * {
			flex-grow: 0;
			flex-shrink: 0;
			@if ($vSpace != false) {
				margin-#{$vSpacePos}: $vSpace;
			}
		}
	}
}
