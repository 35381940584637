// useful when you need to set a minimum aspect-ratio, meaning that, in case,
// the height of the element can grow accordingly to the content.
// If you have only one element inside it does not need to be wrapped

// $ratio is defined as width / height, like 16 / 9 or 1 (a square)
@use "sass:math";

@mixin minAspectRatio($ratio, $pseudoElement: 'before', $childSelector: '& > :first-child') {
	display: flex;
	flex-direction: row;


	&::#{$pseudoElement} {
		content: '';
		display: block;
		flex-grow: 0;
		flex-shrink: 1;
		padding-bottom: math.div(100%, $ratio);
		width: 0;
	}


	#{unquote($childSelector)} {
		flex-grow: 1;
		width: 100%;
	}
}
