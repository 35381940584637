.ubf-footer {
	@include ubf-font(footer);
	background-color: $colorBgFooter;
	color: $colorFooter;
	position: relative;
	z-index: 15;

	@include media($largeLayoutBreakpoint) {
		z-index: 3;
	}

	&.ubf-js-covered {
		position: fixed;
		width: 100%;
		left: 0;
		z-index: 3;
	}


	&__content {
		@include ubf-container($onlyLarge: true);
	}


	&__menu {
		padding-bottom: $base2;
		padding-top: $base2;

		& + & {
			padding-top: 0;

			&::before {
				border-top: $boxBorderSize solid currentColor;
				content: '';
				display: block;
				padding-bottom: $base2;
			}
		}


		&--logos {
			overflow: hidden;
			position: relative;
		}
	}


	&__menuItem {
		.ubf-footer__menu--social & {
			margin-right: $base2;

			&:last-child {
				margin-right: 0;
			}
		}

		.ubf-footer__menu--content & {
			padding-bottom: $base * 1.5;

			&:last-child {
				@include media($largeLayoutBreakpoint) {
					padding-bottom: 0;
				}
			}
		}

		.ubf-footer__menu--logos & {
			height: $base8;
			flex-shrink: 0;
			flex-grow: 0;
			margin-left: $base3;
			margin-top: $base2;

			@include media($largeLayoutBreakpoint) {
				margin-left: $base5;
			}
		}
	}


	&__menuItems {
		.ubf-footer__menu--logos & {
			align-items: stretch;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			height: $base10;
			justify-content: flex-start;
			margin-left: -$base3;
			margin-top: -$base2;
			padding-bottom: $base2;

			@include media($largeLayoutBreakpoint) {
				margin-left: -$base5;
			}

			html.no-js & {
				flex-wrap: wrap;
				height: auto;
				padding-bottom: 0;
			}
		}

		.ubf-footer__menu--social & {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			line-height: 0;
		}

		.ubf-footer__menu--content & {
			@mixin showSubmenu() {
				opacity: 1;
				position: relative;
				transform: none;
				visibility: inherit;
			}

			@include ease(opacity visibility, $duration4);
			opacity: 0;
			visibility: hidden;
			padding-left: $base5;
			position: absolute;

			html.no-js & {
				transform: translateX(-200%);

				@include media($largeLayoutBreakpoint) {
					transform: none;
				}
			}

			&:focus-within {
				outline: none;

				html.no-js & {
					@include showSubmenu();
				}
			}

			&:not(.ubf-js-collapsed):not(.legacy-js-collapsed) {
				@include showSubmenu();
			}

			@include media($largeLayoutBreakpoint) {
				@include showSubmenu();
				padding-left: 0;
				transition: none;
			}
		}
	}


	&__menuLink {
		@include ubf-outlineOnFocus('&', '', $focusOutlineColorInverted);
		color: inherit;
		display: inline-block;
		text-decoration: none;

		@include onHover() {
			text-decoration: underline;
		}

		&--section {
			font-weight: bold;
			margin-bottom: $base;

			@include media($largeLayoutBreakpoint) {
				margin-bottom: $base2;
				pointer-events: none;
			}
		}

		.ubf-footer__menu--logos & {
			height: 100%;
			position: relative;
		}

		&::after {
			content: '';
			display: none;
			position: absolute;
			z-index: 2;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		.ubf-footer__menu--logos &::after {
			display: block;
		}
	}


	&__menuLinkIcon {
		display: inline-block;
		line-height: 0;

		.ubf-footer__menuSection & {
			@include ease(transform, $duration4);
			height: $base;
			margin-right: $base * 0.5;
			position: relative;
			text-align: center;
			width: $base;

			@include media($largeLayoutBreakpoint) {
				display: none;
			}


			[data-type="svg"] {
				height: 100%;
				width: auto;
			}
		}

		html.no-js .ubf-footer__menuSection:focus-within &,
		.ubf-footer__menuLink.ubf-js-toggled & {
			transform: rotate(90deg);
		}

		.ubf-footer__menu--social & {
			width: $base4;

			[data-type="svg"] {
				height: auto;
				width: 100%;
			}
		}
	}


	&__menuLinkLabel {
		.ubf-footer__menu--social & {
			@include visuallyHidden();
		}
	}


	&__menuSection {
		@include ease(height, $duration4);
		position: relative;
		overflow: hidden;
		width: staticColumnSize(1, 1, $gridGutterLarge);

		@include media($largeLayoutBreakpoint) {
			overflow: visible;
			transition: none;
			width: staticColumnSize(3, 12, $gridGutterLarge);
		}
	}


	&__menuSections {
		@include staticColumns($gridGutterSmall, bottom);
		position: relative;

		@include media($largeLayoutBreakpoint) {
			@include staticColumns($gridGutterLarge, bottom);
		}
	}


	&__picture {
		width: auto;
		height: 100%;
		-webkit-user-drag: none;
	}


	&__sliderNavigation {
		left: 0;
		height: 100%;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 100%;
	}


	&__sliderNavigationButton {
		@include fadeOut($duration2);
		align-items: center;
		background-color: $colorBgFooter;
		border: 0;
		display: flex;
		height: 100%;
		justify-content: flex-end;
		left: 0;
		opacity: 0;
		position: absolute;
		pointer-events: all;
		top: 0;
		width: 100%;

		&.ubf-js-enabled {
			@include fadeIn();
		}


		[data-type="svg"] {
			height: $base;
			width: auto;
		}
	}


	&__sliderNavigationItem {
		bottom: 0;
		position: absolute;
		top: $boxBorderSize * 2;
		width: $base * 1.5;


		&--left {
			left: 0;
			transform: rotate(180deg);
		}

		&--right {
			right: 0;
		}
	}
}
