.ubf-social {
	.ubf-sidebar .ubf-contentBlock--social:not(:last-child) & {
		@include ubf-highlightColor(border-bottom-color);
		border-bottom-style: solid;
		border-bottom-width: $boxBorderSize;
		padding-bottom: $base2;
	}
	/* vb-12.08.21: changed margin-left to 1.5rem because added linkedin icon */
	&__item {
		& + & {
			margin-left: 1.5rem;
		}
	}

/* vb-12.08.21: changed justify-content to center because added linkedin icon */
	&__items {
		display: flex;
		flex-direction: row;
		justify-content: center;
		line-height: 0;
		flex-wrap: wrap;
    	row-gap: 2rem;
    	column-gap: 1rem;
	}


	&__link {
		@include ubf-outlineOnFocus();
		color: inherit;
		display: inline-block;
		text-decoration: none;
	}


	&__linkIcon {
		display: inline-block;
		width: $base4;

		[data-type="svg"] {
			height: auto;
			width: 100%;
		}
	}


	&__linkLabel {
		@include visuallyHidden();
	}
}
