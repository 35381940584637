.ubf-mainnews-item {
	/* Border only on mobile Device */
	@include media('>xSmall','<medium') {
		@include ubf-highlightColor(border-color);
		border-width: $boxBorderSize;
		border-style: solid;
	}

	&--bordered {
		@include ubf-highlightColor(border-color);
		border-width: $boxBorderSize;
		border-style: solid;
		@include ease(background-color color, $duration2);
		&:hover {
			@include ubf-highlightColor(background-color);
		}
		& .ubf-mainnews-item__summary {
			padding: $base1;
		}
	}

	&__link {
		text-decoration: none;
		.ubf-mainnews-item .ubf-mainnews-item__title & {
			text-decoration: none;
		}
		color:$colorBase;
	}

	&__picture {
		width: 100%;
		@include media('>xSmall','<medium') {
			margin-bottom:0px!important;
		}
		& .ubf-picture__img {
			width: 100%!important;
		}

		.ubf-mainnews-item:not(.ubf-mainnews-item--noCrop) & {
			@include aspectRatio(1);
		}
	}

	&__summary {
		@include media('>xSmall','<medium') {
			padding: $base1;
			float:left;
			width:50%;
			position:relative;
			height:100%;
		}

		.ubf-mainnews-item__visual + & {
			border-top-width: 0;
		}

		.ubf-body:not(.ubf-body--internal) & {
			border-width: 0;
		}
	}
	&__copyright {
		padding-right:5px;
		margin-top:-30px;
		@include ubf-font(caption);
		padding-top: 5px;
		text-align: right;
		width:100%;
		@include media('>xSmall','<medium') {
			position: absolute;
			bottom:0px;
			right:3px;
		 }

	}

	&__text {
		@include media('>xSmall','<medium') {
			display:none;
		 }
		@include ubf-font(base);

		.ubf-mainnews-item__title + & {
			margin-top: $base * 0.5;

			@include media($largeLayoutBreakpoint) {
				margin-top: $base;
			}
		}
	}

	&__thumbLink {
		@include ubf-outlineOnFocus();
		display: block;
		color: currentColor;
	}


	&__title {
		@include ubf-font(h3);
		@include media($smallLayoutOnly) {
			@include ubf-font(h);
		}

		margin-top:1rem!important;

		.ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				@include ubf-font(h);
			}
		}
	}

	&__visual {
		@include media('>xSmall','<medium') {
			width: 50%;
			float:right;
		}
		.ubf-mainnews-item__summary + & {
			padding-bottom: $base2;

			.ubf-mainnews-item:not(.ubf-mainnews-item--fixedBorder) & {
				@include ubf-highlightColor(border-bottom-color);
				border-bottom-style: solid;
				border-bottom-width: $boxBorderSize;
			}

			.ubf-body:not(.ubf-body--internal) & {
				border-bottom-width: 0;
				padding-bottom: 0;
			}
		}
	}
}
