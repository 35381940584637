@use "sass:math";

$base: 1rem;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;

$baseHalf: $base * 0.5;

$logoSizeSmall: $base * 3.5;
$logoSizeLarge: $base * 4.5;
$logoMarginSmall: $base;
$logoMarginLarge: $base * 1.5;
$campaignLogoHeightSmall: 1.3rem;
$campaignLogoHeightLarge: 1.6rem;
$campaignLogoLeftPositionSmall: 16rem;
$campaignLogoLeftPositionLarge: 21rem;

// difference in the height between the name and the logo
$nameLogoRatio: math.div(42, 45);
$facultyLogoRatio: math.div(47, 45);

$containerPaddingSmall: $base;
$containerPaddingIbrid: $logoSizeSmall + $logoMarginSmall * 2;
$containerPaddingLarge: $logoSizeLarge + $logoMarginLarge * 2;

$gridMaxWidth: $base * 106; // 1060px, not including the padding
$gridMaxWidthIbrid: $base * 52; // 520px, which is the size of the text blocks in large layout, not including padding
$containerMaxWidthIbrid: $gridMaxWidthIbrid + $containerPaddingIbrid * 2; // including the padding
$containerMaxWidthLarge: $gridMaxWidth + $containerPaddingLarge * 2; // including the padding

$gridGutterSmall: $base;
$gridGutterLarge: $base2;

$paddingTopDefaultSmall: 100vw;
$paddingTopDefaultLarge: 80vh;
$paddingTopHomeLarge: 100vh;
$paddingTopHomeLargeMobile: 95vh;
$paddingTopNoCoverSmall: $base * 11;
$paddingTopNoCoverLarge: $base * 14.5;

$paddingTopMiniCoverSmall: $base * 30;
$paddingTopMiniCoverSmallLandscape: $base * 21;
$paddingTopMiniCover: $base * 42;

$paddingTopMicroCoverSmall: $base * 20;
$paddingTopMicroCoverSmallLandscape: $base * 15;
$paddingTopMicroCover: $base * 26;

$boxBorderSize: 2px;
$tableCellBorderSize: 1px;
$tableHeaderBorderSize: 2px;

$headerHeightSmall: $base * 5.5;
$menuHeight: $base8;

$menuTogglerOffset: $base2;
$menuTogglerLineSize: $base * 0.2;
$menuTogglerLineSpace: $base * 0.8;
$menuTogglerWidth: $base * 2.5;
$menuTogglerHeight: $menuTogglerLineSpace * 2 + $menuTogglerLineSize * 3;
$submenuSizeAlternate: 215%;

$searchInputLineHeightSmall: $base * 3.5;

$sectionVerticalSpaceSmall: $base3;
$sectionVerticalSpaceLarge: $sectionVerticalSpaceSmall * 2;
$pageTopPadding: $base2;


$maxGalleryItemCount: 20;
$galleryNavigationIconSize: $base * 1.5;
$gallerySlidesRatio: 3 * 0.5;

$videoRatio: math.div(16, 9);

$contentElementSpace: 1em;

$bildBoxSpace: 2rem;

$inputPadding: $base * 0.65;
$inputNestedPadding: $inputPadding - 0.5;
$labelNestedPadding: $base * 0.4;
$inputToLabelVerticalMargin: $baseHalf;
$inputBorderSize: 1px;
$buttonBorderSize: $inputBorderSize;
$buttonMinWidth: $base * 16;
$radioOuterSize: $base2;
$radioInnerSize: $base * 1.2;

$contentIconSize: $base * 2.2;

$alertMaxWidth: $base * 64;

$ekvvGridMaxWidth: $base * 200; // 2000px, not including the padding
$ekvvContainerMaxWidth: $ekvvGridMaxWidth + $logoMarginLarge * 2; // including the padding

$ekvvMaxWidthReference: $base * 142; // 1420px

$weekCalendarPxPerMinute: 1px;
$weekCalendarCollisionMargin: 2px;
$cardItemsMargin: 2px;

$ekvvLinkTriangleSize: $base1 * 0.72;

$ekvvAssignmentPlanMinWidth: 768px;

$ekvvRoomsMinColumns: 9;
$ekvvRoomsMaxColumns: 13;

$maxCoursesFeatures: 14;
$courseIconSize: $base * 1.5;
$coursesFeaturesSpacing: $base3;
$coursesFeaturesCrowdedSpacing: $base2;
$coursesFeaturesCrowdedLimit: 7;

$outlineCoverSize: $base * 100;

$sizeVariants: (
	logoGeometry: (
		height: $logoSizeSmall,
		left: $logoMarginSmall,
		width: $logoSizeSmall,
		top: $base,
		variants: (
			$xlargeLayoutBreakpoint: (
				height: $logoSizeLarge,
				left: $logoMarginLarge,
				width: $logoSizeLarge,
				top: $base2,
			)
		)
	),
	logoBarGeometry: (
		height: $base,
		left: $logoMarginSmall,
		width: $logoSizeSmall,
		top: $base + $logoSizeSmall + $logoMarginSmall,
		variants: (
			$xlargeLayoutBreakpoint: (
				left: $logoMarginLarge,
				width: $logoSizeLarge,
				top: $base2 + $logoSizeLarge + $logoMarginLarge,
			)
		)
	),
	nameGeometry: (
		height: $logoSizeSmall * $nameLogoRatio,
		left: $logoMarginSmall * 2 + $logoSizeSmall,
		width: auto,
		top: $base + $logoSizeSmall - $logoSizeSmall * $nameLogoRatio,
		margin-top: 1px, // fix the alignment with the logo
		variants: (
			$xlargeLayoutBreakpoint: (
				height: $logoSizeLarge * $nameLogoRatio,
				left: $logoMarginLarge * 2 + $logoSizeLarge,
				width: auto,
				top: $base2 + $logoSizeLarge - $logoSizeLarge* $nameLogoRatio,
			)
		)
	),

	campaignGeometry: (
		left: $campaignLogoLeftPositionSmall,
		width: auto,
		top: $base + $logoSizeSmall - $logoSizeSmall * $nameLogoRatio + $logoSizeSmall * $nameLogoRatio - $campaignLogoHeightSmall,
		height: $campaignLogoHeightSmall,
		variants: (
			$xlargeLayoutBreakpoint: (
				left: $campaignLogoLeftPositionLarge,
				width: auto,
				top: $base2 + $logoSizeLarge - $logoSizeLarge* $nameLogoRatio + $logoSizeLarge * $nameLogoRatio - $campaignLogoHeightLarge,
				height: $campaignLogoHeightLarge
			)
		)
	),

	facultyGeometry: (
		height: $logoSizeSmall * $facultyLogoRatio,
		left: $logoMarginSmall * 2 + $logoSizeSmall,
		width: auto,
		top: $base + $logoSizeSmall + $logoMarginSmall,
		margin-top: -2px,	// fix the alignment with the rectangle bar
		variants: (
			$xlargeLayoutBreakpoint: (
				height: $logoSizeLarge * $facultyLogoRatio,
				left: $logoMarginLarge * 2 + $logoSizeLarge,
				width: auto,
				top: $base2 + $logoSizeLarge + $logoMarginLarge
			)
		)
	)
);
