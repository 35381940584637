// Grid system ideal when you don't know in advance how many children you are going to have
//
// Features:
// 	- it is left aligned, so you don't need to know in advance the exact layout
// 	- does not need any special style for the last child of the last row
// Limitations:
// 	- it is based on negative margins, so it cannot be directly nested
// 		(a grid cell cannot be directly a grid container, it needs an additional wrapper)
// 	- it does not work well when the container is also having a max-width and horizontal paddings
//
// Alternative: _static-columns.scss

// grid container
@mixin dynamicColumns($hSpace: false, $vSpace: false, $hSpacePos: left, $vSpacePos: top, $childrenSelector: '> *') {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: if($vSpace != false, wrap, nowrap);
	justify-content: flex-start;
	@if ($hSpace != false) {
		margin-#{$hSpacePos}: -$hSpace;
	}
	@if ($vSpace != false) {
		margin-#{$vSpacePos}: -$vSpace;
	}

	@if ($childrenSelector != false) {
		#{unquote($childrenSelector)} {
			flex-grow: 0;
			flex-shrink: 0;
			@if ($hSpace != false) {
				margin-#{$hSpacePos}: $hSpace;
			}
			@if ($vSpace != false) {
				margin-#{$vSpacePos}: $vSpace;
			}
		}
	}
}
