.ubf-modalItem {
	display: none;
	flex-direction: column;

	&.ubf-js-active {
		display: flex;
	}


	&__caption {
		@include ubf-font(caption);
		color: $colorBgBase;
		flex-grow: 0;
		flex-shrink: 0;
		padding: $base2 0;
		text-align: right;
	}


	&__images {
		flex-grow: 1;
		flex-shrink: 1;
		position: relative;
	}


	&__image {
		@include objectFit(contain, 50% 50%);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		&--large {
			z-index: 2;
		}

		&--small {
			z-index: 1;
		}
	}
}
