.ubf-button {
	@include button();
	@include ubf-highlightColor(background-color border-color);
	@include ubf-font(base);
	@include ubf-invertedColorFaculties();
	@include ease(background-color, $duration2);
	border-width: $buttonBorderSize;
	border-style: solid;
	font-weight: bold;
	min-width: $buttonMinWidth;
	padding: $inputPadding;
	text-align: center;

	@include onHover {
		outline: $inputBorderSize solid $colorBase;
	}

	&:focus {
		outline: $inputBorderSize solid $colorBase;
	}


	&--pagination {
		border-color: $colorBase;
		min-width: $base4;
	}

	&--paginationIdentifier {
		border-color: $colorBase;
		min-width: $base4;

		@include onHover() {
			outline: inherit;
			cursor: auto;
		}

		&:focus {
			outline: inherit;
		}
	}

	&--next,
	&--prev {
		@include ubf-highlightColor(background-color border-color);
	}

	//vb-08.09.2021: Added featured-links-button
	&--featuredLink {
		width:100%!important;
	}

	&--generic {
		.ubf-body & {
			background-color: $colorHighlightGeneric;
			border-color: $colorHighlightGeneric;
		}
	}

	&--link {
		@include ubf-link();
		background-color: transparent;
		border-color: transparent;
		padding: 0;
		min-width: 0;
		text-align: left;

		@include onHover {
			outline: 0;
		}

		.ubf-form--login & {
			@include ubf-link($boxed: true);
			background-color: transparent;
			border-color: transparent;
		}
	}

	&--prominent {
		@include media($ibridLayoutBreakpoint) {
			min-width: ($buttonMinWidth * 2 + $base2);
		}
	}

	&.ubf-button--seamless {
		@include ubf-link();
		background-color: transparent;
		border-color: transparent;
		padding-left: 0;
		padding-right: 0;
		min-width: 0;

		@include onHover {
			outline: 0;
		}
	}

	&--tableSort {
		@include ubf-link();
		align-items: center;
		background-color: transparent;
		border-color: transparent;
		bottom: 0;
		display: none;
		justify-content: flex-start;
		left: 0;
		min-width: 0;
		padding: 0;
		position: absolute;
		right: 0;
		padding-right: $base1;
		top: 0;
		width: 100%;

		@include onHover {
			outline: 0;
		}

		.ubf-form.ubf-js-sortable & {
			@include media($largeLayoutBreakpoint) {
				display: flex;
			}
		}

		.ubf-form.ubf-js-sortable .ubf-ugc__table--compareLayout & {
			display: flex;
		}
	}


	&--secondary {
		//exception to win over faculty styles and keep it simple
		color: $colorBase !important;
		background-color: transparent !important;
	}

	&--scrollToTop {
		opacity: 0;
		visibility: hidden;
		background-color: $colorBgSubMenu;
		border: none;
		@include ubf-font(footer);

		.ubf-body--ekvv & {
			margin-right: $base2;
			@include media($largeLayoutBreakpoint) {
				margin-left: dynamicColumnSize(9, 12, $gridGutterLarge, $base4);
			}
		}

		.ubf-scrollToTop & {
			@include media($scrollTopOutsideBreakpoint) {
				pointer-events: auto;
				position: relative;
				transform: translateX(100%) translateX($gridGutterLarge);
			}
		}

		&.ubf-js-visible {
			visibility: inherit;
			opacity: 1;
		}

		span[data-type="svg"] {
			height: getFontSize(base);
			transform: rotate(-90deg);
			margin-right: $base1;
		}

		.ubf-html.ubf-js-edge &,
		.ubf-html.ubf-js-ie & {
			visibility: visible;
		}
	}

	&--groupToggler {
		@include ubf-link();
		background-color: transparent;
		border-color: transparent;
		min-width: 0;
		padding-left: 0;
		padding-right: 0;
		width: 100%;
		justify-content: flex-end;

		@include onHover {
			outline: 0;
		}
	}

	.ubf-accordion--boxed &--groupToggler {
		@include ubf-font(footer);
		justify-content: center;
		background-color: $colorBgSubMenu;
		border: none;
		width: 100%;
	}

	.ubf-html .ubf-body .ubf-accordion &--togglerExpand {
		margin-bottom: $base1;
		display: flex;


		&::before {
			display: none;
		}
	}

	.ubf-html .ubf-body .ubf-accordion.ubf-js-hasToggled &--togglerExpand {
		display: none;
	}

	.ubf-html .ubf-body .ubf-accordion &--togglerCollapse {
		display: none;
		margin-bottom: $base1;

		&::before {
			display: none;
		}
	}

	.ubf-html .ubf-body .ubf-accordion.ubf-js-hasToggled &--togglerCollapse {
		display: flex;
	}

	.ubf-field--search & {
		min-width: 0;

		@include media($largeLayoutBreakpoint) {
			min-width: $buttonMinWidth;
		}
	}

	.ubf-field--file .ubf-field__button--file + & {
		margin-top: $base2;

		@include media($largeLayoutBreakpoint) {
			margin-top: 0;
		}
	}


	&__icon {
		.ubf-button--pagination & {
			height: $base;
			width: auto;
		}

		.ubf-button--pagination.ubf-button--prev & {
			position: relative;
			transform: rotate(180deg);
		}

		.ubf-button--tableSort & {
			@include ease(transform, $duration4);
			transform-origin: center;
			height: $base;
			margin-left: $baseHalf;
			transform: rotate(0);
		}

		.ubf-button--tableSort.ubf-js-ascending & {
			transform: rotate(90deg);
		}

		.ubf-button--tableSort.ubf-js-descending & {
			transform: rotate(-90deg);
		}
	}
}
