@mixin ubf-link($boxed: false) {
	@include ubf-outlineOnFocus();
	color: $colorBase;
	font-weight: bold;
	text-decoration: underline;

	.ubf-textBox & {
		@include ubf-invertedColorFaculties();
	}

	// html:not(.mobile) &:hover,
	// &:active {
	&:not([disabled]):hover {
		@if ($boxed) {
			color: $colorBase;
			text-decoration: none;
		} @else {
			@include ubf-highlightColor(color);
		}

		.ubf-textBox & {
			.ubf-body--medicine &,
			.ubf-body--psychology &,
			.ubf-body--history & {
				color: $colorInvertedHover;
			}
		}
	}
}
