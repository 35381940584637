// the regular version is directly requested in the <head> in order to prefetch the file
@font-face {
	font-family: 'Lelo';
	src:
		url('../fonts/Lelo-Bold.woff2') format('woff2'),
		url('../fonts/Lelo-Bold.woff') format('woff');
	font-style: normal;
	font-weight: bold;
}

@font-face {
	font-family: 'Lelo';
	src:
		url('../fonts/Lelo-RegularItalic.woff2') format('woff2'),
		url('../fonts/Lelo-RegularItalic.woff') format('woff');
	font-style: italic;
	font-weight: normal;
}
