.ubf-accessibilityTools {
	@include ubf-highlightColor(border-top-color);
	@include ubf-highlightColor(border-bottom-color);
	// background-color: $colorBgSubMenu;
	border-top-style: solid;
	border-bottom-style: solid;
	border-top-width: 2px;
	border-bottom-width: 2px;
	padding-top: $base1;
	padding-bottom: $base1;
}
