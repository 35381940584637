.ubf-featuredLinks {
	@include dynamicColumns($hSpace: $gridGutterSmall, $vSpace: $gridGutterLarge, $hSpacePos: right);


	@include media($largeLayoutBreakpoint) {
		@include dynamicColumns($hSpace: $gridGutterLarge, $vSpace: $gridGutterLarge, $hSpacePos: right);
	}

	&__item {
		width: dynamicColumnSize(1, 1, $gridGutterSmall);

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(1, 3, $gridGutterLarge);
		}

		.ubf-contentBlock--featuredLinksExtended & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 4, $gridGutterLarge);
			}
		}

		.ubf-contentBlock--featuredLinksReduced & {
			@include media($largeLayoutBreakpoint) {
				width: dynamicColumnSize(1, 2, $gridGutterLarge);
			}

			.ubf-body:not(.ubf-body--internal) .ubf-contentBlock--sidebar + & {
				@include media($largeLayoutBreakpoint) {
					width: dynamicColumnSize(1, 3, $gridGutterLarge);
				}
			}
		}
	}
}
