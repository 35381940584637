.ubf-searchResultsSplitted {
	position: relative;

	@include media($largeLayoutBreakpoint) {
		@include dynamicColumns($hSpace: $gridGutterLarge, $vSpace: $gridGutterLarge, $hSpacePos: left, $vSpacePos: bottom);
	}


	&__category {
		@include ubf-highlightColor(border-left-color);
		@include ease(height, $duration4);
		border-left-style: solid;
		border-left-width: $boxBorderSize;
		margin-bottom: $base;
		overflow: hidden;
		position: relative;
		width: 100%;

		@include media($largeLayoutBreakpoint) {
			border: 0;
			margin-bottom: 0;
			width: dynamicColumnSize(4, 9, $gridGutterLarge);
		}

		&--first {
			order: 3;
		}

		&--second {
			order: 4;

			@include media($largeLayoutBreakpoint) {
				margin-left: dynamicColumnPush(1, 9, $gridGutterLarge);
			}
		}
	}


	&__categoryTitle {
		@include ubf-font(base);
		font-weight: normal;
		width: 100%;

		@include media($largeLayoutBreakpoint) {
			@include ubf-font(h);
			@include ubf-highlightColor(border-bottom);
			border-bottom-style: solid;
			border-bottom-width: $boxBorderSize;
			padding-bottom: $baseHalf;
			width: dynamicColumnSize(4, 9, $gridGutterLarge);
		}

		&--first {
			order: 1;
		}

		&--second {
			order: 2;

			@include media($largeLayoutBreakpoint) {
				margin-left: dynamicColumnPush(1, 9, $gridGutterLarge);
				position: relative;
			}


			&::before {
				@include media($largeLayoutBreakpoint) {
					@include ubf-highlightColor(border-bottom);
					content: '';
					border-bottom-style: solid;
					border-bottom-width: $boxBorderSize;
					display: block;
					pointer-events: none;
					position: absolute;
					top: 100%;
					right: 100%;
					width: 100%;
					height: 0;
				}
			}
		}
	}


	&__item {
		@include ubf-font(base);

		& + & {
			margin-top: $base2;
		}
	}


	&__itemDescription {
		margin: 0;
	}


	&__itemLink {
		color: inherit;
		text-decoration: none;

		&:hover {
			@include ubf-highlightColor(color);
			text-decoration: underline;
		}
	}


	&__itemName {
		display: block;
		font-weight: bold;
	}


	&__items {
		@include ease(opacity, $duration4);
		left: 0;
		margin: 0;
		padding: $base 0 0 $base;
		position: relative;
		right: 0;
		top: 0;

		&:focus {
			outline: 0;
		}

		@include media($largeLayoutBreakpoint) {
			padding: 0;
		}

		&.ubf-js-collapsed {
			// opacity: 0;
			position: absolute;

			html.no-js &:focus-within {
				opacity: 1;
				position: relative;
			}

			@include media($largeLayoutBreakpoint) {
				opacity: 1;
				position: relative;
				transition-duration: 0;
			}
		}
	}


	&__togglerIcon {
		@include ease(transform, $duration4);
		display: inline-block;
		height: $base;
		line-height: 0;
		margin-left: $base * 0.5;
		margin-right: 0;
		order: 2;
		position: relative;
		transform: rotate(90deg);
		width: auto;

		.ubf-searchResultsSplitted__togglerLink.ubf-js-toggled & {
			transform: rotate(270deg);
		}

		@include media($largeLayoutBreakpoint) {
			display: none;
		}


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}


	&__togglerLabel {
		display: inline-block;
		order: 1;
	}


	&__togglerLink {
		@include storeValuesForJs((togglerEnabled: true));
		@include ease(background-color border-color, $duration4);
		align-items: center;
		background-color: $colorBgTabs;
		border-color: transparent;
		border-style: solid;
		border-width: 0 0 0 $boxBorderSize;
		color: $colorTabs;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 100%;
		// padding: $baseHalf $base;
		padding: $baseHalf $base;
		position: relative;
		text-decoration: none;

		&.ubf-js-toggled {
			@include ubf-highlightColor(border-color);
			background-color: transparent;
		}

		@include media($largeLayoutBreakpoint) {
			@include storeValuesForJs((togglerEnabled: false));
			background-color: transparent;
			border: 0;
			color: $colorBase;
			cursor: default;
			display: block;
			padding: 0;
			pointer-events: none;
		}
	}
}
