@use "sass:math";

.ubf-calendar {
	// we would like to expand the days list on the sides
	// in order to fit more with the design (numbers on the sides touching the container border)
	@mixin expandMargins() {
		// we expand of half tile length - 0.5em on each side
		margin-left: calc(#{50% * (1 - math.div(7, 6))} + 0.6em);
		margin-right: calc(#{50% * (1 - math.div(7, 6))} + 0.6em);
	}

	@include ubf-highlightColor(border-color);
	@include ubf-font(h3);
	border-width: $boxBorderSize;
	border-style: solid;
	line-height: 1;
	// overflow: hidden;
	padding: $base2;
	position: relative;

	@include media($largeLayoutBreakpoint) {
		padding: $base4;
	}

	.ubf-sidebar & {
		border-left: 0;
		border-right: 0;

		@include media($largeLayoutBreakpoint) {
			@include ubf-font(base);
			line-height: 1;
			padding: $base2 0;
		}
	}


	.ubf-sidebar .ubf-contentBlock:not(:first-child) & {
		border-top: 0;
		padding-top: 0;

		@include media($largeLayoutBreakpoint) {
			padding-top: 0;
		}
	}



	&__day {
		font-weight: normal;
		position: relative;
		text-align: center;
		width: dynamicColumnSize(1, 7, 0);

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(1, 7, 0);
		}

		&:first-child {
			// day of week is in range [0, 6] with 0 = sunday, so we need to manipulate it because our week starts with Monday
			@for $i from 0 through 6 {
				&[data-ubf-calendar-day-of-week="#{$i}"] {
					@if (($i + 6) % 7 != 0) {
						margin-left: dynamicColumnPush(($i + 6) % 7, 7, 0);
					}
				}
			}
		}

		&[data-ubf-calendar-is-today="true"] {
			font-weight: bold;
		}


		&:hover {
			z-index: 32;
		}
	}


	&__dayLink {
		@include ubf-outlineOnFocus();
		color: currentColor;
		display: block;
		position: relative;
		text-decoration: none;

		&::before {
			@include ease(opacity, $duration2);
			@include ubf-highlightColor(border-color);
			content: '';
			background-color: $colorBgBase;
			border-radius: 50%;
			border-style: solid;
			border-width: 1px;
			height: $base3;
			left: 50%;
			opacity: 0;
			position: absolute;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: $base3;
			z-index: 0;
		}

		.ubf-body:not(.ubf-body--faculty) &::before {
			border-color: $colorHighlightBaseText;
		}

		&.ubf-js-selected::before {
			@include ubf-highlightColor(background-color);
			opacity: 1;
		}

		&:hover::before {
			opacity: 1;
		}

		&:not(.ubf-js-selected)[data-ubf-events-count]::after {
			@include ubf-highlightColor(background-color);
			content: '';
			border-radius: 50%;
			height: $base3;
			left: 50%;
			position: absolute;
			top: 105%;
			transform: translateX(-50%) scale(0.2);
			transform-origin: 50% 0;
			width: $base3;
			z-index: 0;
		}

		.ubf-body:not(.ubf-body--faculty) &:not(.ubf-js-selected)[data-ubf-events-count]::after {
			background-color: $colorHighlightBaseText;
		}
	}


	&__days {
		@include dynamicColumns(0, $gridGutterSmall);
		@include expandMargins();

		@include media($largeLayoutBreakpoint) {
			@include dynamicColumns(0, $gridGutterLarge);
			@include expandMargins();
		}

		.ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				@include dynamicColumns(0, $gridGutterSmall);
				@include expandMargins();
			}
		}
	}


	&__dayValue {
		position: relative;
		z-index: 1;

		.ubf-calendar__dayLink.ubf-js-selected & {
			@include ubf-invertedColorFaculties();
		}
	}


	&__header {
		@include ubf-font(h3);
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		line-height: 1;
		margin-bottom: $base2;
		text-align: center;

		html.no-js & {
			justify-content: center;
		}

		@include media($largeLayoutBreakpoint) {
			margin-bottom: $base4;
		}

		.ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				@include ubf-font(base);
				font-weight: bold;
				line-height: 1;
				margin-bottom: $base2;
			}
		}
	}


	&__monthLink {
		@include ubf-outlineOnFocus();
		@include ubf-highlightColor(color);
		display: inline-block;
		height: $base2;
		line-height: 0;
		position: relative;
		text-align: center;
		text-decoration: none;
		width: auto;

		.ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				height: $base;
			}
		}

		&--prev {
			transform: rotate(180deg);
		}

		html.no-js & {
			display: none;
		}


		[data-type="svg"] {
			width: auto;
			height: 100%;
		}
	}

	&__readerValue {
		@include visuallyHidden();
	}


	&__weekDay {
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		width: staticColumnSize(1, 7, 0);

		@include media($largeLayoutBreakpoint) {
			width: staticColumnSize(1, 7, 0);
		}
	}


	&__weekDays {
		@include staticColumns();
		@include expandMargins();
		margin-bottom: $base2;

		@include media($largeLayoutBreakpoint) {
			margin-bottom: $base4;
		}

		.ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				margin-bottom: $base2;
			}
		}
	}
}
