.ubf-dayEvent {
	background-color: $colorBgBase;

	.ubf-accordion & {
		background-color: $colorEkvvBgBase;

		@include media($largeLayoutBreakpoint) {
			background-color: transparent;
		}
	}

	@include media($largeLayoutBreakpoint) {
		background-color: transparent;
		overflow: hidden;
		z-index: 1;
	}

	&:hover,
	&:focus {
		@include media($largeLayoutBreakpoint) {
			overflow: visible;
			z-index: 2;
		}
	}

	&:focus-within {
		@include media($largeLayoutBreakpoint) {
			overflow: visible;
			z-index: 2;
		}
	}

	&__content {
		@include ubf-font(base);
		padding: $base;
		position: relative;

		// we need to override an inline style only on small screen, so this is required
		@include media($smallLayoutOnly) {
			width: 100% !important; // stylelint-disable-line
		}

		@include media($largeLayoutBreakpoint) {
			background-color: $colorEkvvEventBg;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			min-height: 100%;
		}

		.ubf-dayEvent--sideCollision & {
			@include media($largeLayoutBreakpoint) {
				margin-left: $weekCalendarCollisionMargin;
			}
		}

		.ubf-dayEvent--startCollision & {
			@include media($largeLayoutBreakpoint) {
				margin-top: $weekCalendarCollisionMargin;
			}
		}

		&--preview {
			@include media($largeLayoutBreakpoint) {
				position: relative;
				z-index: 1;
			}


			.ubf-dayEvent:focus &,
			.ubf-dayEvent:hover & {
				@include media($largeLayoutBreakpoint) {
					display: none;
				}
			}

			// focus within not supported on IE, separated selector will not make previous selector invalid
			.ubf-dayEvent:focus-within & {
				@include media($largeLayoutBreakpoint) {
					display: none;
				}
			}
		}

		&--full {
			display: none;

			@include media($largeLayoutBreakpoint) {
				background-color: $colorEkvvEventBgHighlight;
				display: block;
				position: absolute;
				top: 0;
				visibility: hidden;
				z-index: 2;
			}

			.ubf-dayEvent:hover &,
			.ubf-dayEvent:focus & {
				@include media($largeLayoutBreakpoint) {
					visibility: inherit;
				}
			}

			.ubf-dayEvent:focus-within & {
				@include media($largeLayoutBreakpoint) {
					visibility: inherit;
				}
			}
		}
	}


	&__icon {
		bottom: $base;
		display: block;
		line-height: 0;
		height: $base;
		text-align: center;
		right: $base;
		width: $base;
		position: absolute;

		@include media($largeLayoutBreakpoint) {
			display: none;
		}


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}


	&__info {
		@include ubf-font(weekEventInfo);
		margin-right: $base2;

		.ubf-dayEvent__title + & {
			margin-top: $base;
		}

		@include media($largeLayoutBreakpoint) {
			flex-grow: 0;
			flex-shrink: 0;
			margin-right: 0;
		}
	}


	&__link {
		color: inherit;
		text-decoration: none;

		&::before {
			content: '';
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
		}
	}


	&__removeLink {
		color: inherit;
		display: block;
		line-height: 0;
		position: absolute;
		right: $base;
		top: $base;
		width: $base * 1.5;
		height: $base * 1.5;
		z-index: 2;

		[data-type="svg"] {
			height: 100%;
			width: auto;
		}

		.ubf-dayEvent__content--preview & {
			@include media($largeLayoutBreakpoint) {
				display: none;
			}
		}

		.ubf-dayEvent:not(.ubf-dayEvent--sideCollision) & {
			visibility: visible;
		}
	}


	&__title {
		@include ubf-font(base);
		font-weight: bold;
		padding-right: $base2;

		@include media($largeLayoutBreakpoint) {
			flex-grow: 1;
			flex-shrink: 0;
		}
	}
}
