@mixin fadeIn($duration: false, $easing: '', $delay: '') {
	@if ($duration != false) {
		@include ease(opacity visibility, $duration, $easing, $delay);
	}
	opacity: 1;
	visibility: inherit;
}

@mixin fadeOut($duration: false, $easing: '', $delay: '') {
	@if ($duration != false) {
		@include ease(opacity visibility, $duration, $easing, $delay);
	}
	opacity: 0;
	visibility: hidden;
}
