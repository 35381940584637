.ubf-pagination {
	&__item {
		& + & {
			margin-left: $base;

			@include media($largeLayoutBreakpoint) {
				margin-left: $base2;
			}
		}
	}


	&__link {
		&--current {
			@include ubf-highlightColor(border-color);
		}
	}


	&__items {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
}
