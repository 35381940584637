.ubf-revealMore {
	&.ubf-js-active > * {
		display: none;
	}

	&.ubf-js-active > *:nth-child(-n + 7) {
		display: inherit;
	}

}
.ubf-revealMoreToggle {
	margin-top: $base;
	display: none;

	&.ubf-js-active {
		display: inline-flex;
	}

	.ubf-revealMoreToggle__label {
		&--less {
			display: inherit;
		}
		&--all {
			display: none;
		}
	}
	&.ubf-js-less .ubf-revealMoreToggle__label {
		&--less {
			display: none;
		}
		&--all {
			display: inherit;
		}
	}
}
