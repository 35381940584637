.ubf-eventfeed {
	@include staticColumns($gridGutterSmall);
	position: relative;

	@include media('>medium') {
		@include staticColumns($gridGutterLarge);
	}


	&__item {
		display: block;
		position: relative;
		width: staticColumnSize(1, 1, $gridGutterSmall);


		@include media('>medium') {
			width: staticColumnSize(1, 3, $gridGutterLarge);
		}

		padding: $gridGutterSmall;
		@include ubf-highlightColor(border-color);
		border-width: $boxBorderSize;
		border-style: solid;
		text-decoration: none;
		color: $colorBase;

		@include ease(background-color color, $duration2);
		&:hover {
			@include ubf-highlightColor(background-color);
		}
	}
	&__title {
		@include ubf-font(base);
		font-weight: bold;
		padding-top: $base2;
	}
	&__date,
	&__time {
		@include ubf-font(h4);

		@include media($largeLayoutBreakpoint) {
			@include ubf-font(h3);
		}
	}
	&__moreLink {
		@include ubf-link();
	}
}
