.ubf-highlights {
	@include staticColumns($gridGutterSmall);
	position: relative;

	@include media($largeLayoutBreakpoint) {
		@include staticColumns($gridGutterLarge);
	}


	// &__excerpts {
	// 	* + & {
	// 		margin-top: $base2;
	// 	}
	// }


	&__item {
		position: relative;
		width: staticColumnSize(1, 2, $gridGutterSmall);

		&:not(&--news) {
			@include minAspectRatio(1);
		}

		@include media('>xxSmall') {
			width: staticColumnSize(1, 3, $gridGutterSmall);
		}

		@include media($largeLayoutBreakpoint) {
			width: staticColumnSize(1, 7, $gridGutterLarge);
		}
		// The number of elements is fixed.
		// Only on iphone5 sized screen (320px) the layout is going to be
		//	|  1 |  2 |
		//	|  3 |  4 |
		//	|  5 |  6 |
		//	|  7 |  8 |
		//	|  news   |
		//	|         |
		//	|  9 | 10 |
		//
		// on other small screens:
		//	|  1 |  2 |  3 |
		//	|  4 |  5 |  6 |
		//	|  7 |  8 |  9 |
		//	|    news      |
		//	|              |
		//	|              |
		//	| 10 | 11 | 12 |
		//
		// on big screen we need to achieve this order:
		//	|  1 |  2 |  3 |    news    | 10 |
		//	|  4 |  5 |  6 |            | 11 |
		//	|  7 |  8 |  9 |            | 12 |
		//
		// the news is sitting in last (13) position, we arrange its position with negative margins
		// it should be a square but in the worst case (too much content), it will grow
		$order: 1;
		@for $i from 1 through 12 {
			@if ($i == 4 or $i == 7) {
				$order: $order + 1;
			} @else if ($i > 9) {
				$order: ($i - 9) * 4;
			}

			&:nth-child(#{$i}) {
				@if ($i > 8) {
					order: $i + 1;
				}

				@include media('>xxSmall') {
					@if ($i > 9) {
						order: $i + 1;
					}
				}

				@include media($largeLayoutBreakpoint) {
					order: $order;
				}
			}

			$order: $order + 1;
		}

		&:nth-child(n + 10):not(&--news) {
			@include media($largeLayoutBreakpoint) {
				margin-left: staticColumnPush(3, 7, $gridGutterLarge);
			}
		}


		&--news {
			order: 9;
			width: staticColumnSize(3, 3, $gridGutterSmall);

			@include media('>xxSmall') {
				order: 10;
			}

			@include media($largeLayoutBreakpoint) {
				@include minAspectRatio(1);
				margin-left: staticColumnPush(3, 7, $gridGutterLarge);
				margin-top: staticColumnPush(-3, 7, $gridGutterLarge, $gridGutterLarge);
				order: 13;
				position: relative;
				width: staticColumnSize(3, 7, $gridGutterLarge);
			}
		}
	}


	&__label {
		display: block;
		min-height: 100%;
		padding: $base;
		position: relative;
		z-index: 4;

		@include media($largeLayoutBreakpoint) {
			@include ease(background-color color, $duration2);
			padding: $base * 1.5;
		}

		.ubf-highlights__link:hover & {
			@include media($largeLayoutBreakpoint) {
				@include ubf-highlightColor(background-color);
				@include ubf-invertedColorFaculties();
			}
		}

		.ubf-highlights__figure + & {
			@include media($largeLayoutBreakpoint) {
				@include ease(opacity, $duration2);
				@include ubf-highlightColor(background-color);
				opacity: 0;
			}

			.ubf-highlights__link:hover & {
				@include media($largeLayoutBreakpoint) {
					opacity: 1;
				}
			}
		}
	}


	&__link {
		@include ubf-outlineOnFocus();
		color: currentColor;
		font-weight: bold;
		display: block;
		height: 100%;
		width: 100%;
		text-decoration: none;

		&::before {
			@include ubf-highlightColor(border-color);
			border-width: $boxBorderSize;
			border-style: solid;
			content: '';
			position: absolute;
			height: 100%;
			left: 0;
			top: 0;
			width: 100%;
			z-index: 1;
		}

		&:hover {
			text-decoration: none;
			color: currentColor;
		}
	}


	&__picture {
		position: absolute;
		height: 100%;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 2;

		&::after {
			background-color: transparentize($colorBgBase, $transparenceHighlightBg);
			content: '';
			position: absolute;
			height: 100%;
			left: 0;
			top: 0;
			width: 100%;
			z-index: 3;

			.ubf-highlights__link:hover & {
				@include media($largeLayoutBreakpoint) {
					opacity: 1;
				}
			}
		}
	}
}
