.ubf-searchResults {
	&__item {
		& + & {
			@include ubf-highlightColor(border-top-color);
			border-top-style: solid;
			border-top-width: $boxBorderSize;
			margin-top: $base2;
			padding-top: $base2;
		}
		.ubf-ugc &:before {
			content:none;
		}
		.ubf-ugc &Title a {
			font-weight:700!important;
		}
	}

	&__itemInfo, &__itemInfoData {
		color: $colorSecondaryInfo;
	}
	.ubf-ugc &__itemInfoLabel {
		font-weight: bold;
	}
}
