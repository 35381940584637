.ubf-videoreel {
	@include dynamicColumns($hSpace: $gridGutterSmall, $vSpace: $gridGutterLarge, $hSpacePos: right);


	@include media($largeLayoutBreakpoint) {
		@include dynamicColumns($hSpace: $gridGutterLarge, $vSpace: $gridGutterLarge, $hSpacePos: right);
	}

	&--slider {
		--itemsPerSlide: 1;
		flex-wrap: nowrap;
		overflow: hidden;
	  	user-select: none;

		@include media($ibridLayoutBreakpoint) {
			--itemsPerSlide: 2;
		}

		@include media($largeLayoutBreakpoint) {
			--itemsPerSlide: 4;
			flex-wrap: nowrap;
			overflow: hidden;
		}


		&::after {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			width: 2px;
			z-index: 10;
			background-color: $colorBgBase;
			height: 100%;
		}
	}

	&__videoreel-item {
		width: dynamicColumnSize(1, 1, $gridGutterSmall);

		@include media($ibridLayoutBreakpoint) {
			width: dynamicColumnSize(1, 2, $gridGutterSmall);
		}
		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(1, 4, $gridGutterLarge);
		}
		.ubf-videoreel--slider & {
			@include ease(transform, $duration4);
			position: relative;
			transform: translateX(calc(-100% * var(--sliderItemIndex, 0))) translateX(calc(-#{$gridGutterSmall} * var(--sliderItemIndex, 0)));

			@include media($largeLayoutBreakpoint) {
				transform: translateX(calc(-100% * var(--sliderItemIndex, 0))) translateX(calc(-#{$gridGutterLarge} * var(--sliderItemIndex, 0)));
			}
		}
	}
}
.ubf-contentBlock--videoreel .ubf-previewsSliderNavigation {
	pointer-events: none;
	top: calc(80% - 4rem * 0.5);
	@include media($ibridLayoutBreakpoint) {
		//position: relative;
		//top: 0;
	}
}

