@mixin ubf-highlightColor($properties: color, $opacity: 1) {
	@each $property in $properties {
		#{$property}: if($property == color, transparentize($colorHighlightBaseText, 1-$opacity), transparentize($colorHighlightBase, 1-$opacity));
	}

	@each $faculty, $value in $colorHighlightFaculties {
		.ubf-body--#{$faculty} & {
			@each $property in $properties {
				#{$property}: if($property == color, transparentize(map-get($value, text), 1-$opacity), transparentize(map-get($value, default), 1-$opacity));
			}
		}
	}
}
