.ubf-accordion {
	position: relative;


	&__content {
		@include dynamicColumns($hSpace: $gridGutterSmall, $vSpace: $gridGutterLarge, $hSpacePos: left, $vSpacePos: bottom);
		@include ease(opacity visibility, $duration4);
		left: 0;
		padding: $base 0 0 $base;
		position: relative;
		right: 0;
		top: 0;

		&:focus {
			outline: 0;
		}

		ol {
			list-style-position: inside;
			margin-left: 0!important; 
		}

		.ubf-body--ekvv & {
			background-color: $colorBgBase;
		}

		.ubf-accordion--alternateColor & {
			background-color: $colorEkvvBgBase;
		}


		@include media($largeLayoutBreakpoint) {
			@include floatColumns($hSpace: $gridGutterLarge, $vSpace: $gridGutterLarge, $hSpacePos: left, $vSpacePos: bottom);
		}

		&.ubf-js-collapsed {
			opacity: 0;
			position: absolute;
			visibility: hidden;

			html.no-js &:focus-within {
				opacity: 1;
				position: relative;
				visibility: inherit;
			}
		}

		.ubf-accordion--openedOnDesktop &.ubf-js-collapsed {
			@include media($largeLayoutBreakpoint) {
				opacity: 1;
				position: relative;
				visibility: inherit;
			}
		}


		.no-js &.ubf-js-collapsed {
			opacity: 1;
			position: relative;
			visibility: inherit;
		}
	}


	&__contentWrapper {
		@include ease(height, $duration4);
		margin: 0 0 $base 0;
		overflow: hidden;
		position: relative;
		width: 100%;

		.ubf-accordion--boxed & {
			@include ubf-highlightColor(border-left-color);
			border-left-style: solid;
			border-left-width: $boxBorderSize;

			.ubf-body--ekvv & {
				border-left-width: 0;
			}
		}
	}


	&__toggler {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		position: relative;
		width: 100%;

		.ubf-accordion--boxed & {
			justify-content: space-between;
			padding: $baseHalf $base;
		}

		.ubf-accordion--alternateColor & {
			padding: 0;
		}
	}


	&__togglerExtraInfo {
		order: 2;
		position: relative;
		z-index: 4;
	}


	&__togglerIcon {
		@include ease(transform, $duration4);
		display: inline-block;
		flex-grow: 0;
		height: $base;
		line-height: 0;
		margin-right: $base * 0.5;
		order: 1;
		position: absolute;
		top: 0;
		margin-top: .8rem;
		width: auto;
		z-index: 2;
		

		.ubf-accordion--boxed & {
			margin-left: $base;
			position: relative;
			margin: 0;
			order: 3;
			transform: rotate(90deg);
		}

		.ubf-accordion--openedOnDesktop & {
			@include media($largeLayoutBreakpoint) {
				display: none;
			}
		}

		.ubf-accordion__togglerLink.ubf-js-toggled ~ & {
			transform: rotate(90deg);

			.ubf-accordion--boxed & {
				transform: rotate(270deg);
			}
		}


		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}


	&__togglerLabel {
		display: inline-block;
		position: relative;
	}


	&__togglerLink {
		@include ubf-outlineOnFocus();
		color: $colorBase;
		flex-grow: 1;
		font-weight: bold;
		height: 100%;
		order: 2;
		text-decoration: none;
		padding-left: 1.4rem;
		.ubf-accordion--boxed & {
			padding-left: 0;
		}

		.ubf-accordion--openedOnDesktop & {
			@include media($largeLayoutBreakpoint) {
				pointer-events: none;
			}
		}

		.ubf-accordion--boxed & {
			.ubf-body--ekvv & {
				font-weight: normal;

				&.ubf-js-toggled {
					font-weight: bold;
				}
			}
		}

		&::before {
			.ubf-accordion--boxed & {
				content: '';
				@include ease(background-color border-color, $duration4);
				background-color: $colorBgTabs;
				border-color: transparent;
				border-style: solid;
				border-width: 0 0 0 $boxBorderSize;
				bottom: 0;
				color: $colorTabs;
				left: 0;
				order: 1;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 0;

				.ubf-body--ekvv & {
					background-color: $colorBgBase;
					border-width: 0;
					color: $colorBase;
					font-weight: normal;

					&.ubf-js-toggled {
						font-weight: bold;
					}
				}
			}

			.ubf-accordion--alternateColor & {
				.ubf-body--ekvv & {
					background-color: $colorEkvvBgBase;
				}
			}
		}

		&.ubf-js-toggled::before {
			@include ubf-highlightColor(border-color);
			background-color: transparent;
		}


		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			background-color: transparent;
			z-index: 3;
		}
	}
}
