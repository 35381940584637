.ubf-galleryItem {
	&__caption {
		@include ubf-font(caption);
		color: $colorBgBase;
		flex-grow: 0;
		flex-shrink: 0;
		padding: $base 0 0 0;
		text-align: right;
	}


	&__image {
		@include media($largeLayoutBreakpoint) {
			@include aspectRatio(1);
			position: relative;
		}
	}


	&__link {
		color: currentColor;
		display: block;
		text-decoration: none;
		pointer-events: none;

		@include media($largeLayoutBreakpoint) {
			pointer-events: all;
		}
	}


	&__picture {

	    .ubf-picture__img {
		  width: 100%;
	    }
		@include media($largeLayoutBreakpoint) {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
	&__videoIcon {
	  @include ubf-highlightColor(background-color);
	  opacity: 0.85;
      color: $colorInverted;
	  padding: 8px;
	  height: $base4;
	  left: 50%;
	  position: absolute;
	  top: 50%;
	  text-align: center;
	  width: $base4;
	  margin-left: -$base2;
	  margin-top: -$base2;

	  [data-type="svg"] {
		width: 100%;
		height: 100%;
	  }
	}
}
