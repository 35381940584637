.ubf-excerpts {
	position: relative;

	&__excerpt {
		& + & {
			margin-top: $base2;
		}
	}


	&__moreLink {
		@include ubf-link();
		display: inline-block;

		.ubf-textBox & {
			@include ubf-link($boxed: true);
		}

		* + & {
			margin-top: $base;

			@include media($largeLayoutBreakpoint) {
				margin-top: $base2;
			}
		}
	}
}
